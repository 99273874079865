import { Component, OnInit, ApplicationRef, Output } from '@angular/core'
import { BookingDetailService } from '../services/bookingDetail.service'
import { BookingInfoDto} from '../models/bookingInfo.model'
import { BookingDetailDto } from '../models/bookingDetail.model';

@Component({
    templateUrl: 'bookingDetail.component.html',
})

export class BookingDetailComponent implements OnInit {
    data: BookingInfoDto;
    details: BookingDetailDto[];
    constructor(private blackListsService: BookingDetailService ) { }
    ngOnInit() {
        this.data = history.state.data;
        this.details = this.data.Details;
    }

    getTerminalName(terminalId: number): string{
        switch (terminalId) {
            case 3: return 'Omaha'
            case 4: return 'KC Nicholson'
            case 5: return 'Denver'
            case 6: return 'Dodge City Kansas'
            case 7: return 'Des Moines'
            case 36: return 'Council Bluffs'
            case 41: return 'Fort Worth'
            case 43: return 'KC 12th St'
            case 46: return 'KC Kansas Ave'
            case 47: return 'KC Edgerton'
            case 49: return 'Denver North'
            case 21879: return 'KC Gardner'
        }
          return 'Unknown Terminal'
    }
}