import { Injectable } from '@angular/core';
import {BookingQueueDto} from '../models/bookingQueue.model'
import { HttpHeaders, HttpParams } from '@angular/common/http';
import {HttpService} from '../../shared/services/HttpService.service'
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BookingStateUpdateViewModel } from '../models/bookingStateUpdateViewModel.model';

@Injectable() 
export class BookingQueueService {
    private bookingQueueUrl = 'api/bookingcheck'
    blacklistarray: BookingQueueDto[];

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };
    constructor(
        private httpService: HttpService
    ){}

    getQueue(skip:number, take:number): Observable<BookingQueueDto[]> {
        const routeUrl = this.bookingQueueUrl + "/Queue";
        let params:HttpParams = new HttpParams().set('skip', skip.toString()).set('take', take.toString());
        return this.httpService.getList(routeUrl, params);
    }

    updateStatus(vm:BookingStateUpdateViewModel) : Observable<any>{
        return this.httpService.post<any>(this.bookingQueueUrl + "/UpdateStatus", vm).pipe(
            catchError(this.handleError<any>('add Black Lists', []))
        );
    }

    handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
    
          console.error(error); // log to console
        
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
    }
}