import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//Blacklists
import {BlackListsService} from './blacklists/services/blacklists.service'
import {BlackListsComponent} from './blacklists/components/blacklists.component'

//BookingCheck
import {BlackListedService} from './bookingCheck/services/blacklisted.service'
import {BlackListedComponent} from './bookingCheck/components/blacklisted.component'
import {BookingCheckService} from './bookingCheck/services/bookingCheck.service'
import {BookingCheckComponent} from './bookingCheck/components/bookingCheck.component'
import {BookingDetailService} from './bookingCheck/services/bookingDetail.service'
import {BookingDetailComponent} from './bookingCheck/components/bookingDetail.component'
import {NotFoundService} from './bookingCheck/services/notFound.service'
import {NotFoundComponent} from './bookingCheck/components/notFound.component'

//BookingQueue
import {BookingQueueService} from './bookingQueue/services/bookingQueue.service'
import {BookingQueueComponent} from './bookingQueue/components/bookingQueue.component'

//Claims
import {ClaimsService} from './claims/services/claims.service'
import {ClaimsComponent} from './claims/components/claims.component'

//Auth
import { AuthService } from './auth/auth.service'

//PendingLogins
import {PendingLoginsService} from './pendingLogins/services/pendinglogins.service'
import {PendingLoginsComponent} from './pendingLogins/components/pendinglogins.component'

//Signups
import {SignupService} from './signup/services/signup.service'
import {SignupComponent} from './signup/components/signup.component'

//SteamshipEmail
import {SteamshipEmailService} from './steamshipEmail/services/steamshipemail.service'
import {SteamshipEmailComponent} from './steamshipEmail/components/steamshipemail.component'

//Shared
import { HttpService } from './shared/services/HttpService.service'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { SteamshipLookupService } from './shared/services/steamshipLookup.service'
import { TokenInterceptorService } from './auth/services/tokenInterceptor.service'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    BlackListsComponent,
    BlackListedComponent,
    BookingCheckComponent,
    BookingDetailComponent,
    NotFoundComponent,
    BookingQueueComponent,
    ClaimsComponent,
    PendingLoginsComponent,
    SignupComponent,
    SteamshipEmailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    InfiniteScrollModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    BlackListsService,
    BlackListedService,
    BookingCheckService,
    BookingDetailService,
    NotFoundService,
    BookingQueueService,
    ClaimsService,
    PendingLoginsService,
    SignupService,
    SteamshipEmailService,
    HttpService,
    SteamshipLookupService,
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
