import { Component, OnInit, ApplicationRef, Output } from '@angular/core'
import {BlackListsService} from '../services/blacklists.service'
import { BlackListsDto } from '../models/blacklists.model';
import { SteamshipLookupService } from 'src/app/shared/services/steamshipLookup.service';
import { SteamshipLookupDto } from 'src/app/shared/models/steamshipLookup.model';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: "blacklists",
    templateUrl: 'blacklists.component.html',
})

export class BlackListsComponent implements OnInit {
    columns = [];
    blacklists: BlackListsDto[];
    steamshipLookups: SteamshipLookupDto[];
    terminalAreas: String[];

    constructor(private blackListsService: BlackListsService, private steamshipLookupService: SteamshipLookupService, private toastr: ToastrService ) { }
    ngOnInit() {
        this.getData();
    }

    getData() {
        this.blacklists = null;
        this.columns = [           
            { field: 'BlackListId', header: 'BlackList Id' }, 
            { field: 'TerminalArea', header: "Terminal Area"},
            { field: 'SteamShipId', header: 'Steamship Id' }, 
            { field: 'BookingNumber', header: 'Booking Number' }];
        this.blackListsService.getBlackLists().subscribe(res => {
        this.blacklists = res;
        },error=>{
            this.blacklists=[];
        });
        this.getSteamshipLookups();
    }

    getSteamshipLookups(){
        this.steamshipLookups = null;
        this.steamshipLookupService.getTerminalSteamships().subscribe(res => {
            this.steamshipLookups = res;
        },error=>{
            this.steamshipLookups = [];
        });
    }

    getSteamShipName(steamShipId: number): string {
        if (this.steamshipLookups === null || this.steamshipLookups === undefined){
            return "";
        }
        const currentSteamship = this.steamshipLookups.find(x=> x.Id == steamShipId);
        if (currentSteamship === null || currentSteamship === undefined){
            return "";
        }
        return currentSteamship.Name;
    }

    setAreas(event: any){
        const selectedSteamship = this.steamshipLookups.find(x=> x.Id == event.target.value);
        this.terminalAreas = selectedSteamship.Areas;
    }

    removeBlackList(BlackList: BlackListsDto) {
        this.blackListsService.removeBlackLists(BlackList);
        this.getData();
    }

    addBlacklist(bookingNumber: string, steamShip: number, terminalArea: string){
        if(steamShip == 0) {
            this.toastr.error('Input Error', 'Please Select a Steamship');
            return;
        }

        if(bookingNumber == undefined || bookingNumber == '') {
            this.toastr.error('Input Error', 'Please Enter a Booking Number');
            return;
        }

        if(terminalArea == undefined || terminalArea == '') {
            this.toastr.error('Input Error', 'Please Select a Location');
            return;
        }

        const blackList: BlackListsDto = {
            BookingNumber: bookingNumber,
            SteamshipId: steamShip,
            TerminalArea: terminalArea
        };
        this.blackListsService.addBlackLists(blackList).subscribe(s=> {
            location.reload();
        });
    }
}