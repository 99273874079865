import { Component, OnInit, ApplicationRef, Output } from '@angular/core'
import {PendingLoginsService} from '../services/pendinglogins.service'

@Component({
    templateUrl: 'pendinglogins.component.html',
})

export class PendingLoginsComponent implements OnInit {

    constructor(private blackListsService: PendingLoginsService ) { }
    ngOnInit() {

    }
}