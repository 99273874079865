<div class="mdc-layout-grid__cell" infiniteScroll (scrolled)="onScroll()">
    <div class="mdc-card mdc-card--elevated">
        <div class="mdc-tsl-card-header col-lg-12" id="availableAtPortHeader">
            <div class="col-lg-10">
                <strong style="padding-left: 24em;">Booking Queue</strong>
            </div>
        </div>
        <div class="mdc-data-table mdc-tsl-data-table" style="padding-top: 2em;">
            <div *ngIf="bookingQueues" class="mdc-data-table mdc-tsl-data-table">
                <div class="mdc-data-table__table-container">
                    <table class="mdc-data-table__table mdc-tsl-table__table" style="width: 100em;">
                        <thead>
                            <tr class="mdc-data-table__header-row mdc-tsl-data-table__header-row" style="background-color: lightblue;">
                                <th class="mdc-data-table__header-cell mdc-tsl-data-table__header-cell">SetupDateUtc</th>
                                <th class="mdc-data-table__header-cell mdc-tsl-data-table__header-cell">Booking Number</th>
                                <th class="mdc-data-table__header-cell mdc-tsl-data-table__header-cell">Steamship</th>
                                <th class="mdc-data-table__header-cell mdc-tsl-data-table__header-cell">Area</th>
                                <th class="mdc-data-table__header-cell mdc-tsl-data-table__header-cell">Name</th>
                                <th class="mdc-data-table__header-cell mdc-tsl-data-table__header-cell">Email</th>
                                <th class="mdc-data-table__header-cell mdc-tsl-data-table__header-cell">Status</th>
                                <th class="mdc-data-table__header-cell mdc-tsl-data-table__header-cell"></th>
                            </tr>
                        </thead>
                        <tbody class="mdc-data-table__content">
                            <tr *ngFor ="let x of bookingQueues" class="mdc-data-table__row mdc-tsl-data-table__header-row">
                                <td class="mdc-data-table__cell">{{ x.SetupDateUtc | date}}</td>
                                <td class="mdc-data-table__cell">{{ x.BookingNumber }}</td>
                                <td class="mdc-data-table__cell">{{ x.Steamship }}</td>
                                <td class="mdc-data-table__cell">{{ x.Area }}</td>
                                <td class="mdc-data-table__cell">{{ x.Name }}</td>
                                <td class="mdc-data-table__cell">{{ x.Email }}</td>
                                <td class="mdc-data-table__cell"><select #bookingStatus>
                                    <option *ngFor="let y of bookingQueueStatuses;let i = index" value={{y.status}} [selected]="i == x.Status-1">
                                        {{y.name}}
                                    </option>
                                </select></td>
                                <td class="mdc-data-table__cell"><button class="btn btn-success" (click)="updateStatus(x.BookingRequestId, bookingStatus.value)"><span class="mdc-button__label">Update</span></button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
