import { Component, OnInit, ApplicationRef, Output } from '@angular/core'
import {BlackListedService} from '../services/blacklisted.service'

@Component({
    templateUrl: 'blacklisted.component.html',
})

export class BlackListedComponent implements OnInit {

    constructor(private blackListsService: BlackListedService ) { }
    ngOnInit() {

    }
}