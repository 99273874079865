import { Component } from '@angular/core';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'BookingWebsite';

  constructor(public auth: AuthService){ }

  activateLink(element: HTMLElement){
    var activeElements = document.querySelectorAll('.mdc-list-item--activated');
    [].forEach.call(activeElements, function(el){
      el.classList.remove('mdc-list-item--activated');
    });
    element.classList.add('mdc-list-item--activated')
  }
}