import { Inject, Injectable, Optional, ReflectiveInjector, Injector } from '@angular/core';
import {SteamshipEmailDto} from '../models/steamshipemail.model'
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpService } from 'src/app/shared/services/HttpService.service';
import { Observable } from 'rxjs';

@Injectable() 
export class SteamshipEmailService {
    private steamshipUrl = 'api/steamshipemail'
    private apiUrl = 'https://terminalbooking.azurewebsites.net/'
    steamshiparray: SteamshipEmailDto[];

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };
    constructor(
        private http: HttpClient,
        private httpService: HttpService
    ){}

    getSteamshipEmail(): Observable<SteamshipEmailDto[]> {
        return this.httpService.getList<SteamshipEmailDto>(this.steamshipUrl);
    }

    removeSteamshipEmail(steamshipEmail: SteamshipEmailDto){
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: steamshipEmail
        }
        this.httpService.remove(this.steamshipUrl, options);
    }

    addSteamshipEmail(steamshipEmail: SteamshipEmailDto){
        const localUrl = this.apiUrl + this.steamshipUrl;
        return this.http.post(localUrl, steamshipEmail, this.httpOptions);
    }
}