import { Component, OnInit, ApplicationRef, Output } from '@angular/core'
import {ClaimsService} from '../services/claims.service'

@Component({
    templateUrl: 'claims.component.html',
})

export class ClaimsComponent implements OnInit {

    constructor(private blackListsService: ClaimsService ) { }
    ngOnInit() {

    }
}