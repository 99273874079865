<div class="mdc-layout-grid__cell" >
    <div class="mdc-card mdc-card--elevated">
        <div class="mdc-tsl-card-header col-lg-12" id="availableAtPortHeader">
            <div class="col-lg-10">
				<legend style="color:#c9302c;padding-left: 24em;">Booking Information Not Found</legend>
				<h5 style="padding-left: 10em;">TSL Terminals has no record of Booking# {{booking.BookingNumber}}. Please provide your name and email address below and click the "Send" button. The information will then be forwarded to depot@4tsl.com to check for the booking.</h5>
            </div>
        </div>
        <div class="container">

			<div class="row col-lg-9" style="padding-top: 1em;">
				<h4 class ="col=lg-4" style="width: 30%;">Steamship/Customer:</h4>
				<div class ="col-lg-1"></div>
				<h4 class ="col=lg-4">{{steamship}}</h4>
			</div>

			<div class="row col-lg-9" style="padding-top: 1em;">
				<h4 class ="col=lg-4" style="width: 30%;">Location:</h4>
				<div class ="col-lg-1"></div>
				<h4 class ="col=lg-4">{{booking?.TerminalAreaName}}</h4>
			</div>

			<div class="row col-lg-9" style="padding-top: 1em;">
				<h4 class ="col=lg-4" style="width: 30%;">Booking Number:</h4>
				<div class ="col-lg-1"></div>
				<h4 class ="col=lg-4">{{booking?.BookingNumber}}</h4>
			</div>

			<div class="row col-lg-9" style="padding-top: 1em;">
				<h4 class ="col=lg-4" style="width: 30%;">Your Name:</h4>
				<div class ="col-lg-1"></div>
				<input #name type="text" style="width: 12em;">
				<h4 style="color: red" *ngIf="showNameRequired">Name Is Required</h4>
      </div>

      <div class="row col-lg-9" style="padding-top: 1em;">
				<h4 class ="col=lg-4" style="width: 30%;">Your Email Address:</h4>
				<div class ="col-lg-1"></div>
				<input #email type="text" style="width: 12em;">
				<h4 style="color: red" *ngIf="showEmailRequired">Email Is Required</h4>
      </div>

            <div class="row col-lg-12" style="padding-top: 1em;">
                <div class="col-lg-3"></div>
                <button type="submit" id="btnSubmit" name="btnSubmit" class="btn btn-success" style="width: 9em;" (click)="followup(email.value, name.value)">Send</button>
            </div>
        </div>
    </div>
</div>
