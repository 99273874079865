import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../app/auth/services/authGuard';

//BlackLists
import { BlackListsComponent } from './blacklists/components/blacklists.component'

//BookingCheck
import {BlackListedComponent} from './bookingCheck/components/blacklisted.component'
import {BookingCheckComponent} from './bookingCheck/components/bookingCheck.component'
import {BookingDetailComponent} from './bookingCheck/components/bookingDetail.component'
import {NotFoundComponent} from './bookingCheck/components/notFound.component'

//BookingQueue
import {BookingQueueComponent} from './bookingQueue/components/bookingQueue.component'

//Claims
import {ClaimsComponent} from './claims/components/claims.component'

//PendingLogins
import { PendingLoginsComponent } from './pendingLogins/components/pendinglogins.component';

//Signup
import {SignupComponent} from './signup/components/signup.component'

//SteamshipEmail
import {SteamshipEmailComponent} from './steamshipEmail/components/steamshipemail.component'

const routes: Routes = [
  {
    path: 'blacklists/blacklists',
    component: BlackListsComponent,
    data: {
      title: 'BlackLists'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'bookingcheck/blacklisted',
    component: BlackListedComponent,
    data: {
      title: 'BlackListed'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'bookingcheck/bookingchecks',
    component: BookingCheckComponent,
    data: {
      title: 'BookingChecks'
    }
  },
  {
    path: 'bookingcheck/bookingdetails',
    component: BookingDetailComponent,
    data: {
      title: 'BookingDetails'
    }
  },
  {
    path: 'bookingcheck/notfound',
    component: NotFoundComponent,
    data: {
      title: 'NotFound'
    }
  },
  {
    path: 'bookingqueue/bookingqueue',
    component: BookingQueueComponent,
    data: {
      title: 'BookingQueue'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'claims/claims',
    component: ClaimsComponent,
    data: {
      title: 'Claims'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'pendinglogins/pendinglogins',
    component: PendingLoginsComponent,
    data: {
      title: 'PendingLogins'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'signup/signup',
    component: SignupComponent,
    data: {
      title: 'Signups'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'steamshipemail/steamshipemail',
    component: SteamshipEmailComponent,
    data: {
      title: 'SteamshipEmails'
    },
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: '/bookingcheck/bookingchecks',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
