import { Component, OnInit, ApplicationRef, Output } from '@angular/core'
import { BookingCheckDto } from '../models/bookingCheck.model';
import { SteamshipLookupService } from 'src/app/shared/services/steamshipLookup.service';
import { SteamshipLookupDto } from 'src/app/shared/models/steamshipLookup.model';
import { NotFoundService } from '../services/notFound.service';
import { Router } from '@angular/router';

@Component({
    templateUrl: 'notFound.component.html',
})

export class NotFoundComponent implements OnInit {
    booking: BookingCheckDto;
    steamship: string;
    steamshipLookups: SteamshipLookupDto[];
    showEmailRequired: boolean;
    showNameRequired: boolean;

    constructor(private notFoundService: NotFoundService, private steamShipService: SteamshipLookupService, private router: Router ) { }

    ngOnInit() {
        this.showEmailRequired = false;
        this.getData();
    }

    getData() {
        this.booking = history.state.data;
        this.getSteamshipLookups();
    }

    getSteamshipLookups(){
        this.steamshipLookups = null;
        this.steamShipService.getTerminalSteamships().subscribe(res => {
            this.steamshipLookups = res;
            this.steamship = this.steamshipLookups.find(x=> x.Id == this.booking.SteamshipId).Name;
        },error=>{
            this.steamshipLookups = [];
        });
    }

    followup(email: string, name: string){
      this.validateRequiredFields(email, name);
        if (this.showEmailRequired || this.showNameRequired){
            return;
        }
        this.booking.Email = email;
        this.booking.Name = name;
        this.notFoundService.AddToWatch(this.booking);
        this.router.navigate(["bookingcheck/bookingchecks"]);
    }

    validateRequiredFields(email: string, name: string){
      if (email === ""){
        this.showEmailRequired = true;
      } else {
        this.showEmailRequired = false;
      }
      if (name === ""){
        this.showNameRequired = true;
      } else {
        this.showNameRequired = false;
      }
    }
}
