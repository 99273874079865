import { Component, OnInit, ApplicationRef, Output } from '@angular/core'
import { SteamshipEmailService } from '../services/steamshipemail.service'
import { SteamshipLookupDto } from 'src/app/shared/models/steamshipLookup.model';
import { SteamshipEmailDto } from '../models/steamshipemail.model';
import { SteamshipLookupService } from 'src/app/shared/services/steamshipLookup.service';
import { ToastrService } from 'ngx-toastr';
@Component({
    templateUrl: 'steamshipemail.component.html',
})

export class SteamshipEmailComponent implements OnInit {
    
    columns = [];
    steamshipEmails: SteamshipEmailDto[];
    steamshipLookups: SteamshipLookupDto[];
    terminalAreas: String[];
    constructor(private steamshipEmailService: SteamshipEmailService, private steamshipLookupService: SteamshipLookupService, private toastr: ToastrService ) { }
    ngOnInit() {
        this.getData();
    }

    getData() {
        this.steamshipEmails = null;
        this.columns = [           
            { field: 'Id', header: 'Steamship Email Id' }, 
            { field: 'TerminalArea', header: "Terminal Area"},
            { field: 'SteamhipId', header: 'Steamship Id' }, 
            { field: 'EmailAddress', header: 'EmailAddress' }];
        this.steamshipEmailService.getSteamshipEmail().subscribe(res => {
        this.steamshipEmails = res;
        },error=>{
            this.steamshipEmails=[];
        });
        this.getSteamshipLookups();
    }

    getSteamshipLookups(){
        this.steamshipLookups = null;
        this.steamshipLookupService.getTerminalSteamships().subscribe(res => {
            this.steamshipLookups = res;
        },error=>{
            this.steamshipLookups = [];
        });
    }
    
    setAreas(event: any){
        const selectedSteamship = this.steamshipLookups.find(x=> x.Id == event.target.value);
        this.terminalAreas = selectedSteamship.Areas;
    }

    setSteamshipName(id: number){
        const selectedSteamship = this.steamshipLookups.find(x=> x.Id == id);
        if(selectedSteamship == undefined) {
            return id;
        }
        return selectedSteamship.Name;
    }
    
    removeSteamshipEmails(steamShipEmail: SteamshipEmailDto) {
        this.steamshipEmailService.removeSteamshipEmail(steamShipEmail);
        this.getData();
    }
    
    addSteamshipEmail(emailAddress: string, steamShip: number , area: string){
        if(steamShip == 0) {
            this.toastr.error('Input Error', 'Please Select a Steamship');
            return;
        }

        if(area == undefined || area == '') {
            this.toastr.error('Input Error', 'Please Select a Location');
            return;
        }

        if(emailAddress == undefined || emailAddress == '') {
            this.toastr.error('Input Error', 'Please Enter a Email');
            return;
        }

        const steamshipEmail: SteamshipEmailDto = {
            EmailAddress: emailAddress,
            SteamshipId: steamShip,
            AreaName: area
        };
        this.steamshipEmailService.addSteamshipEmail(steamshipEmail).subscribe(res => {
            location.reload();
        })
        
    }
}