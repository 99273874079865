import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service'

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

    constructor(private authService: AuthService){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
         const token = this.authService.getAuthToken();
         let newHeaders = request.headers;
         if (token) {
             const newToken = "Bearer " + token;
         newHeaders = newHeaders.append('Authorization', newToken);
         }
         const authReq = request.clone({headers: newHeaders});
         return next.handle(authReq);
    }
}