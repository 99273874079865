<div class="mdc-layout-grid__cell" >
    <div class="mdc-card mdc-card--elevated">
        <div class="mdc-tsl-card-header col-lg-12" id="availableAtPortHeader">
            <div class="col-lg-10">
                <strong style="padding-left: 24em;">Steamship Emails</strong>
            </div>
        <div class="container">

            <div class="row col-lg-10" style="padding-top: 2em;">
                <h4 class="col-lg-4">Steamship</h4>
                <select class="col-lg-6" #steamShip (change)="setAreas($event)">
                    <option value="0">--Select Steamship--</option>
                    <option *ngFor="let steamship of steamshipLookups" value={{steamship.Id}}>
                        {{steamship.Name}}
                    </option>
                </select>
            </div>

            <div class="row col-lg-10" style="padding-top: 2em;">
                <h4 class="col-lg-4">Location</h4>
                <select class="col-lg-6" #terminalArea>
                <option *ngFor="let area of terminalAreas" value={{area}}>
                    {{area}}
                </option>
                </select>
            </div>

            <div class="row col-lg-10" style="padding-top: 2em;">
                <h4 class="col-lg-4">Email Address</h4>
                <input class="col-lg-6" #emailAddress id="textinput" name="booking" type="text" placeholder="">
            </div>
            <div class="row col-lg-12" style="padding-top: 1em;">
                <div class="col-lg-7"></div>
                <button type="submit" id="btnSubmit" name="btnSubmit" class="btn btn-success" style="width: 7em;" (click)="addSteamshipEmail(emailAddress.value, steamShip.value, terminalArea.value)">Add</button>
            </div>
        </div>
    </div>
</div>
<div class="mdc-layout-grid__cell" style="padding-top: 3em;" *ngIf="steamshipEmails">
    <div class="mdc-card mdc-card--elevated">
        <div class="mdc-data-table mdc-tsl-data-table">
            <div class="mdc-data-table__table-container">
                <table class="mdc-data-table__table mdc-tsl-table__table" style="width: 101em;">
                    <thead>
                        <tr class="mdc-data-table__header-row mdc-tsl-data-table__header-row" style="background-color:lightblue;">
                            <th class="mdc-data-table__header-cell mdc-tsl-data-table__header-cell">Steamship</th>
                            <th class="mdc-data-table__header-cell mdc-tsl-data-table__header-cell">Area</th>
                            <th class="mdc-data-table__header-cell mdc-tsl-data-table__header-cell">Email Address</th>
                            <th class="mdc-data-table__header-cell mdc-tsl-data-table__header-cell"></th>
                        </tr>
                    </thead>
                    <tbody class="mdc-data-table__content">
                        <tr *ngFor ="let x of steamshipEmails" class="mdc-data-table__row mdc-tsl-data-table__header-row">
                            <td class="mdc-data-table__cell"><b>{{ setSteamshipName(x.SteamshipId) }}</b></td>
                            <td class="mdc-data-table__cell"><b>{{ x.AreaName }}</b></td>
                            <td class="mdc-data-table__cell"><b>{{ x.EmailAddress }}</b></td>
                            <td class="mdc-data-table__cell"><button class="btn btn-danger" (click)="removeSteamship(x)"><span class="mdc-button__label">Remove</span></button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>