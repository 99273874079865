import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {HttpService} from '../../shared/services/HttpService.service';
import { BookingCheckDto } from '../models/bookingCheck.model';

@Injectable() 
export class BookingCheckService {
    private apiUrl = 'https://terminalbooking.azurewebsites.net/';
    private endpoint = 'api/BookingCheck';
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };
    constructor(
        private http: HttpClient,
        private httpService: HttpService
    ){}

    getBooking(bookingCheck: BookingCheckDto) : Observable<any>{
        const localUrl = this.apiUrl + this.endpoint + "/GetBooking";
        return this.http.post<any>(localUrl, bookingCheck, this.httpOptions)
      .pipe(
        catchError(this.handleError<any>('get Booking', []))
      );
    }

    handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
    
          console.error(error); // log to console
        
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
    }
}