<div class="container">
  <form [formGroup]="bookingSearch" (ngSubmit)="getBooking()">
  <div class="row">
    <legend style="font-weight:bold; padding-left: 9em;">Request Booking Information</legend>
  </div>

  <div class="row col-lg-9" style="padding-top: 2em; justify-content: space-between;">
      <h4 class="col-lg-4">Steamship/Customer</h4>
      <select #steamShip formControlName="steamShip" [(ngModel)]="model.steamShip"  class="col-lg-5" (change)="setAreas($event)" [ngClass]="ssErrors ? 'input-error' : ''">
        <option value="0">--Select Steamship/Customer--</option>
        <option *ngFor="let steamship of steamshipLookups" value={{steamship.Id}}>
        {{steamship.Name}}
        </option>
    </select>

  </div>

  <div class="row col-lg-9" style="padding-top: 2em; justify-content: space-between;">
    <h4 class="col-lg-4">Location</h4>
    <select #terminalArea formControlName="location" [(ngModel)]="model.location" class="col-lg-5" [ngClass]="locationErrors ? 'input-error' : ''">
      <option value="0">--Select Location--</option>
      <option *ngFor="let area of terminalAreas" value={{area}}>
          {{area}}
      </option>
    </select>
  </div>

  <div class="row col-lg-9" style="padding-top: 2em; justify-content: space-between;">
    <h4 class="col-lg-4">Booking Number</h4>
    <input #bookingNumber formControlName="bookingNumber" [(ngModel)]="model.bookingNumber" class="col-lg-5" id="textinput" name="booking" type="text" placeholder="Enter Booking Number" [ngClass]="bookingNumErrors ? 'input-error' : ''">
  </div>

  <div class="row col-lg-12" style="padding-top: 2em;  justify-content: center;">
      <div class="col-lg-5"></div>
      <button type="submit" id="btnSearch" name="btnSearch" class="btn btn-success center ">Search Bookings</button>
  </div>

</form>
</div>
