import { Component, OnInit, ApplicationRef, Output, ViewChild, Input, ElementRef } from '@angular/core'
import { BookingCheckService } from '../services/bookingCheck.service'
import { SteamshipLookupDto } from 'src/app/shared/models/steamshipLookup.model';
import { SteamshipLookupService } from 'src/app/shared/services/steamshipLookup.service';
import { BookingCheckDto } from '../models/bookingCheck.model';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    templateUrl: 'bookingCheck.component.html',
})

export class BookingCheckComponent implements OnInit {
    steamshipLookups: SteamshipLookupDto[];
    terminalAreas: String[];
    redirectRoute: string;
    bookingSearch!: FormGroup;
    model: any;

    ssErrors: boolean = false;
    locationErrors: boolean = false;
    bookingNumErrors: boolean = false;

    constructor(
      private bookingCheckService: BookingCheckService,
      private steamshipLookupService: SteamshipLookupService,
      private router: Router,
      private formBuilder: FormBuilder
    ) {
      this.model = {
        steamShip: 0,
        location: 0,
        bookingNumber: ''
      };
     }
    ngOnInit() {
        this.getData();
    }



    getData(){
        this.bookingSearch = this.formBuilder.group({
          steamShip: [
            this.model.steamShip,
            [Validators.required, Validators.min(1)]
          ],
          location: [
            this.model.location,
            [Validators.required, Validators.min(1)]
          ],
          bookingNumber: [
            this.model.bookingNumber,
            Validators.required
          ]
        });
        this.getSteamshipLookups();
    }

    getSteamshipLookups(){
        this.steamshipLookups = null;
        this.steamshipLookupService.getTerminalSteamships().subscribe(res => {
            var array = res;
            array.sort((a,b) => a.Name.localeCompare(b.Name));
            this.steamshipLookups = array;
        },error=>{
            this.steamshipLookups = [];
        });
    }

    setAreas(event: any){
        const selectedSteamship = this.steamshipLookups.find(x=> x.Id == event.target.value);
        var array = selectedSteamship.Areas;
        array.sort((a,b) => a.localeCompare(b));
        this.terminalAreas = array;
    }

    setErrors(key: string){
      if (key == 'steamShip'){
        this.ssErrors = true;
      }
      if (key == 'location'){
        this.locationErrors = true;
      }
      if (key == 'bookingNumber'){
        this.bookingNumErrors = true;
      }
    }

    removeErrors(key: string){
      if (key == 'steamShip'){
        this.ssErrors = false;
      }
      if (key == 'location'){
        this.locationErrors = false;
      }
      if (key == 'bookingNumber'){
        this.bookingNumErrors = false;
      }
    }

    getBooking(){
      this.bookingSearch.updateValueAndValidity();
        if (this.bookingSearch.invalid) {
          Object.keys(this.bookingSearch.controls).forEach((key) => {
            const form = this.bookingSearch.get(key);
            if (form !== null) {
              if (!form.valid){
                this.setErrors(key);
              } else {
                this.removeErrors(key);
              }
              form.markAsDirty();
            }
        });
        return;
      }

      if (this.bookingSearch.valid){
        const booking: BookingCheckDto = {
          SteamshipId: this.model.steamShip,
          TerminalAreaName: this.model.location,
          BookingNumber: this.model.bookingNumber
      };

        this.bookingCheckService.getBooking(booking).subscribe(success => {
          this.setRedirectRoute(success, booking);
        }
        );
      }
    }

    setRedirectRoute(result: any, bookingCheck: BookingCheckDto){
        if (result === null){
            this.redirectRoute = "bookingcheck/notfound";
            this.router.navigate([this.redirectRoute], {state: {data: bookingCheck}})
        } else if (result.bookingId === -1) {
            this.redirectRoute = "bookingcheck/blacklisted";
        } else {
            this.redirectRoute = "bookingcheck/bookingdetails";
            this.router.navigate([this.redirectRoute], {state: {data: result}})
        }
    }
}
