import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {HttpService} from '../../shared/services/HttpService.service';
import { BookingCheckDto } from '../models/bookingCheck.model';

@Injectable() 
export class NotFoundService {
    private endpoint = 'api/BookingCheck';
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };
    constructor(
        private httpService: HttpService
    ){}

    AddToWatch(booking: BookingCheckDto) {
        this.httpService.post(this.endpoint + "/AddToWatch", booking).subscribe(s=> {
            console.log(s);
        });;
    }
}