import { Inject, Injectable, Optional, ReflectiveInjector, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpHandler } from '@angular/common/http';
import {BlackListsDto} from '../models/blacklists.model'
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {HttpService} from '../../shared/services/HttpService.service'


@Injectable() 
export class BlackListsService {
    private blackListsUrl = 'api/blacklist'
    private apiUrl = 'https://terminalbooking.azurewebsites.net/'
    blacklistarray: BlackListsDto[];

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };
    constructor(
        private http: HttpClient,
        private httpService: HttpService
    ){}

    getBlackLists(): Observable<BlackListsDto[]> {
        return this.httpService.getList<BlackListsDto>(this.blackListsUrl);
    }

    removeBlackLists(blackList: BlackListsDto){
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: blackList
        }
        this.httpService.remove(this.blackListsUrl, options);
    }

    addBlackLists(blackList: BlackListsDto) : Observable<any>{
        const localUrl = this.apiUrl + this.blackListsUrl;
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: blackList
        }      
        return this.http.post<any>(localUrl, blackList, this.httpOptions).pipe(
            catchError(this.handleError<any>('add Black Lists', []))
        );
    }

    handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
    
          console.error(error); // log to console
        
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
    }
}