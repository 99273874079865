import { Component, OnInit, ApplicationRef, Output } from '@angular/core'
import {BookingQueueService} from '../services/bookingQueue.service'
import { BookingQueueDto } from '../models/bookingQueue.model';
import { BookingQueueStatusDto} from '../models/bookingQueueStatus.model'
import { BookingStateUpdateViewModel} from '../models/bookingStateUpdateViewModel.model'

@Component({
    templateUrl: 'bookingQueue.component.html',
})

export class BookingQueueComponent implements OnInit {

    take:number = 50;
    skip:number = 0;
    bookingQueues = new Array<BookingQueueDto>();
    bookingQueueStatuses: BookingQueueStatusDto[];
    constructor(private bookingQueueService: BookingQueueService ) { }
    ngOnInit() {
        this.getData(this.skip, this.take);
    }

    getData(skip:number, take:number){
        this.bookingQueueService.getQueue(skip, take).subscribe(res => {
            this.bookingQueues = this.bookingQueues.concat(res);
            this.skip += 50;
            },error=>{
                this.bookingQueues=[];
            });
        this.bookingQueueStatuses =  [{status: 1, name: 'Found'},
        {status: 2, name: 'Rejected'},
        {status: 3, name: 'Pending'},
        {status: 4, name: 'RequestSentToSteamShip'},
        {status: 5, name: 'Expired'}];
    }

    updateStatus(bookingQueueId: number, bookingQueueStatus: number){
        const bookingStateVm : BookingStateUpdateViewModel = {
            Id: bookingQueueId,
            State: bookingQueueStatus
        }
        this.bookingQueueService.updateStatus(bookingStateVm).subscribe(s=> {
            this.getData(this.skip, this.take);
        });             
    }

    onScroll() {
        this.getData(this.skip, this.take);
    }

}