import { Component, OnInit, ApplicationRef, Output } from '@angular/core'
import {SignupService} from '../services/signup.service'

@Component({
    templateUrl: 'signup.component.html',
})

export class SignupComponent implements OnInit {

    constructor(private blackListsService: SignupService ) { }
    ngOnInit() {

    }
}