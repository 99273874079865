<!-- Toolbar -->
<header class="mdc-top-app-bar mdc-top-app-bar--fixed">
  <div role="banner" class="mdc-top-app-bar__row elevated" style="padding-right: 15%; padding-left: 15%; margin-right:auto; margin-left:auto; width: 100%;">
    <section class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">
      <img src="../favicon.ico" alt="App Logo" style="max-height: 90%">
      <span class="mdc-top-app-bar__title">Booking Website</span>
    </section>
    <div class="col-md-3 mdc-tsl-user-bar" style="justify-content: center;">
      <div class="btn btn-tsl-white mdc-tsl-signin" (click)="auth.login()" *ngIf="auth.loggedIn === false">Sign In</div>
      <div class="btn btn-tsl-white mdc-tsl-signin" (click)="auth.logout()" *ngIf="auth.loggedIn === true">Log Out</div>
    </div>
  </div>
</header>

<div class="mdc-tsl-adjust-top" role="main">
  <aside class="mdc-drawer mdc-tsl-drawer">
    <div class="mdc-drawer__content">
      <nav class="mdc-list">
        <a class="mdc-list-item mdc-list-item--activated" href="#" aria-current="page" routerLink='bookingcheck/bookingchecks' (click)="activateLink($event.target)">
          <span class="mdc-list-item__ripple"></span>
          <i class="material-icons mdc-list-item__graphic" aria-hidden="true" style="padding-right: 5%;">check_circle_outline</i>
          <span class="mdc-list-item__text">Booking Check</span>
        </a>
        <div *ngIf="auth.isAdmin">
          <hr class="mdc-list-divider">
          <h6 class="mdc-list-group__subheader">Admin</h6>
          <a class="mdc-list-item" href="#" routerLink='blacklists/blacklists' (click)="activateLink($event.target)">
            <span class="mdc-list-item__ripple"></span>
            <i class="material-icons mdc-list-item__graphic" aria-hidden="true"  style="padding-right: 5%;">remove_circle</i>
            <span class="mdc-list-item__text">Black List</span>
          </a>
          <a class="mdc-list-item" href="#" routerLink='bookingqueue/bookingqueue' (click)="activateLink($event.target)">
            <span class="mdc-list-item__ripple"></span>
            <i class="material-icons mdc-list-item__graphic" aria-hidden="true"  style="padding-right: 5%;">queue</i>
            <span class="mdc-list-item__text">Booking Queue</span>
          </a>
          <!-- <a class="mdc-list-item" href="#" routerLink='pendinglogins/pendinglogins' (click)="activateLink($event.target)">
            <span class="mdc-list-item__ripple"></span>
            <i class="material-icons mdc-list-item__graphic" aria-hidden="true">person</i>
            <span class="mdc-list-item__text">Logins</span>
          </a> -->
          <a class="mdc-list-item" href="#" routerLink='steamshipemail/steamshipemail' (click)="activateLink($event.target)">
            <span class="mdc-list-item__ripple"></span>
            <i class="material-icons mdc-list-item__graphic" aria-hidden="true"  style="padding-right: 5%;">drafts</i>
            <span class="mdc-list-item__text">Steamship Emails</span>
          </a>
        </div>
      </nav>
    </div>
  </aside>
  <div class="mdc-layout-grid mdc-tsl-layout-grid">
    <router-outlet></router-outlet>
  </div>
</div>
