import { Observable } from 'rxjs';
import {Injectable} from '@angular/core'
import { SteamshipLookupDto } from '../models/steamshipLookup.model';
import {HttpService} from '../services/HttpService.service'

@Injectable()
export class SteamshipLookupService {
    steamshipArray: SteamshipLookupDto[];
    constructor(
        private httpService: HttpService
    ){}
     
    getTerminalSteamships() : Observable<SteamshipLookupDto[]>{
        const url = 'api/Lookup/steamships'
        return this.httpService.getList<SteamshipLookupDto>(url)
    } 
}