<legend style="color:#419641">Booking Information Found</legend>
	<div>
		<label class="control-label">Booking #:</label>
		{{data.BookingNumber}}
	</div>
	<div>
		<label class="control-label">Terminal:</label>
		{{getTerminalName(data.TerminalId)}}
	</div>
	<table class="table table-hover">
		<thead>
			<tr>
				<td>Equipment type</td>
				<td>Containers booked</td>
				<td>Containers already outgated</td>
				<td>Containers currently available</td>
				<td>Trucker provides wheels</td>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor ="let d of details">
				<td>
					{{d.EquipmentTypeDetail}}
				</td>
				<td>
					{{d.TotalBooked}}
				</td>
				<td>
					{{d.TotalBooked - d.NumberAvailable}}
				</td>
				<td>
					{{d.EquipmentAvailable}} 
				</td>
				<td>
					{{d.NeedsWheels}}
				</td>
			</tr>
		</tbody>
	</table>
    <hr />
    <p class="bg-danger">IMPORTANT: Equipment Availability is subject to change and is not guaranteed.</p>