import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {Injectable} from '@angular/core'

@Injectable()
export class HttpService {
    private apiUrl = 'https://terminalbooking.azurewebsites.net/';
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };
    constructor(
        private http: HttpClient,
    ){}

    getList<T>(endPoint: String, params?:HttpParams) : Observable<T[]>{
        const localUrl = this.apiUrl + endPoint;
        return this.http.get<T[]>(localUrl, { params })
      .pipe(
        catchError(this.handleError<T[]>('get List', []))
      );
    }

    remove(endPoint: string, options: object){
      const localUrl = this.apiUrl + endPoint;
      this.http.delete(localUrl, options).subscribe(s => {
        console.log(s);
      })
    }

    add(endPoint: string, options: any) : Observable<any>{
      const localUrl = this.apiUrl + endPoint;
      alert(options.body.SteamshidpId);
      return this.http.post(localUrl, options).pipe(
        catchError(this.handleError<any>('Add Record', []))
      );
    }

    handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
    
          console.log(error); // log to console
        
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
    }

    post<T>(endPoint: string, data?: any) : Observable<any>{
      const localUrl = this.apiUrl + endPoint;
      const  headers = new  HttpHeaders().set("content-type", "application/json");
      return this.http.post<any>(localUrl, JSON.stringify(data), {headers})
      .pipe(
        catchError(this.handleError<T>('Error calling ' + localUrl))
      );
    }
}